import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import Sidebar from "@/components/Sidebar"
import Menu from "@/components/Menu"
import Cookies from "@/components/Cookies"
import SWUpdater from "@/components/SWUpdater"
import Footer from "@/components/Footer"
import MegaMenu from "@/components/MegaMenu"
import { isSSR } from "@/utils"
import * as style from "./style.module.scss"

let touchStartPrev = 0
let touchStartLocked = false
let prevViewState = 0

const MainLayout = ({ children }) => {
  const dispatch = useDispatch()
  const megaMenuVisible = useSelector((state) => state.settings.megaMenu)
  const sidebarSwitched = useSelector((state) => state.settings.sidebarSwitched)

  // touch slide mobile menu opener
  useEffect(() => {
    if (!isSSR) {
      const unify = (e) => {
        return e.changedTouches ? e.changedTouches[0] : e
      }
      document.addEventListener(
        'touchstart',
        (e) => {
          const x = unify(e).clientX
          touchStartPrev = x
          touchStartLocked = x > 30
        },
        { passive: false },
      )
      document.addEventListener(
        'touchmove',
        (e) => {
          const x = unify(e).clientX
          const prev = touchStartPrev
          if (x - prev > 40 && !touchStartLocked) {
            switchSidebar()
            touchStartLocked = true
          }
        },
        { passive: false },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  const switchSidebar = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'sidebarSwitched',
        value: !sidebarSwitched,
      },
    })
  }

  return (
    <div className={`${style.layout} ${sidebarSwitched ? style.switched : ''}`}>
      <Helmet
        titleTemplate="RayPad — %s"
        title="Cardano Launchpad & IDO Platform"
      >
        <meta
          name="viewport"
          content="width=device-width,viewport-fit=cover,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=0"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/resources/font/satoshi.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/resources/font/ray-icons.css"
        />
        <meta property="og:url" content="https://raypad.io" />
        <meta
          name="description"
          content="RayPad is the only launchpad natively built on Cardano that leverages existing DeFi components of the Cardano ecosystem. It is the best place to launch your token even if you don't have any liquidity. RayPad platform enables price discovery and resistant to bot and sybil attacks."
        />
      </Helmet>
      <SWUpdater />
      <div className={style.layoutInner}>
        <div className={style.sidebar}>
          <div className={style.sidebarInner}>
            <Sidebar />
          </div>
        </div>
        <div className={style.content}>
          <div>
            {megaMenuVisible && <MegaMenu />}
            <Menu />
            {children}
          </div>
          <div className="mt-auto">
            <Footer />
          </div>
        </div>
      </div>
      <Cookies />
    </div>
  )
}

export default MainLayout
